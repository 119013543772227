<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Archived leads')" :description="$t('Total')+' '+pagination.totalRecords+' leads.'"></block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <bulk-action :actions="{export: 'Export'}" @perform-action="performBulkAction"></bulk-action>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( statusFilter !== ''  ? 'primary':'')"
                            :title="'Leads '+$t('Filter')">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" lg="8">
                                <label class="overline-title overline-title-alt" for="status_filter">{{ $t('Status') }}</label>
                                <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                  <option value="">{{ $t('All') }}</option>
                                  <option v-for="status in leadStatuses" :key="'stf'+status.id" :value="status.id">{{ $t(status.status_text) }}</option>
                                </select>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getLeads(true)">{{ $t('Filter') }}</nk-button>
                            <a class="clickable" href="javascript:;" v-on:click="[statusFilter='',getLeads(true)]">{{ $t('Reset Filter') }}</a>
                          </template>
                        </drop-down>
                      </li>
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getLeads(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getLeads(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search lead [client name|lead #]')"
                  :is-active="searchBarActive"
                  @do-search="searchLeads"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchLeads('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <ion-list v-if="$isHybridPlatform">
                  <template v-for="lead in leads" :key="'archived_l_'+lead.id">
                    <ion-item button
                              v-on:click="$router.push({name: 'LeadDetails', params:{id:lead.AL_id}})"
                              lines="full" style="--border-color:#e3eaf5;--padding-bottom: 8px;--padding-top: 8px;">
                      <ion-avatar slot="start" class="user-avatar">
                        <img v-if="lead.dp" :src="lead.dp" alt="Avatar"/>
                        <span v-else class="text-uppercase">{{ lead.initials }}</span>
                      </ion-avatar>
                      <div>
                        <ion-label class="fw-500" color="dark">{{ lead.first_name+' '+lead.last_name}}</ion-label>
                        <ion-text color="medium" class="mt-1 medium d-block">#{{ lead.lead_number }}</ion-text>
                      </div>
                    </ion-item>
                  </template>
                </ion-list>
                <special-table v-else>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="csv_r_select_all" v-model="selectAll"></b-check-box>
                    </table-col>
                    <table-col break-point="md">
                      <span class="sub-text">Lead#</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">{{ $t('Name') }}</span>
                    </table-col>
                    <table-col break-point="mb">{{ $t('Location') }}</table-col>
                    <table-col break-point="md">{{ $t('Received') }}</table-col>
                    <table-col>{{ $t('Status') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="lead in leads" :key="'ld'+lead.id">
                    <table-col has-check-box>
                      <b-check-box :id="'exp_al'+lead.AL_id" v-model="lead.selected" :key="'exp_a'+lead.AL_id"></b-check-box>
                    </table-col>
                    <table-col break-point="md">
                      <router-link :to="{name: 'LeadDetails', params:{id:lead.AL_id}}">{{ lead.lead_number }}</router-link>
                    </table-col>
                    <table-col>
                      <user-small-card
                          :user-name="lead.first_name+' '+lead.last_name"
                          :image="lead.dp" :initials="lead.initials">
                        <template v-slot:more_info>
                          <span>{{ lead.post_code+' '+lead.city}}{{ (lead.province.length) ? ', '+lead.province:''}}</span>
                          <span class="d-block d-md-none">Lead#: {{lead.lead_number}}</span>
                        </template>
                      </user-small-card>
                    </table-col>
                    <table-col break-point="mb">
                      <span>{{ lead.city}}, {{ lead.province }}</span>
                      <span class="d-block">{{ lead.post_code }}</span>
                    </table-col>
                    <table-col break-point="md">{{ lead.received_time }}</table-col>
                    <table-col>
                      <span :style="{color: lead.status_color}">{{ $t(lead.status_text) }}</span>
                      <template v-if="['follow up', 'appointment'].includes(lead.status_text.toLowerCase())">
                        <span class="d-block text-muted small fw-500">{{ $t('on') +' '+ lead.event_date+' '+$t('at')+' '+lead.event_time}}</span>
                      </template>
                    </table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'LeadDetails', params:{id:lead.AL_id}}"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <leads-csv-export-modal
        :selected-leads="leadsCSVExport.selectedLeads"
        :modal-open="leadsCSVExport.modalOpen"
        @modal-close="leadsCSVExport.modalOpen=false"></leads-csv-export-modal>
  </page-template>
</template>

<script>
import {IonSpinner, onIonViewWillEnter, IonList, IonItem, IonAvatar, IonLabel, IonText} from '@ionic/vue';
import {defineComponent, reactive, ref, watch} from 'vue';
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";
import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import { BlockContent, BlockHead, Column, MainBlock, Row} from "@core/layouts"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import "bootstrap"
import Pagination from "v-pagination-3"

import { useStore } from "vuex"
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import axios from "@/libs/axios"
import DropDown from "@core/components/dropdown/DropDown";
import PageTemplate from "@core/layouts/page/PageTemplate";
import BulkAction from "@/views/components/BulkAction";
import useCommonFunc from '@core/comp-functions/common';
import LeadsCsvExportModal from "@/views/leads-export/components/LeadsCsvExportModal";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";

export default defineComponent({
  components: {
    BCheckBox,
    LeadsCsvExportModal,
    BulkAction,
    PageTemplate,
    DropDown,
    ListItem,
    DropDownList,
    UserSmallCard,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
    IonList, IonItem, IonAvatar, IonLabel, IonText
  },
  setup() {

    const store   = useStore()
    const { updateTracker }     = useDataChangeTracker()
    const leadStatuses = store.state.general_data.leads_statuses
    const {getSelectedRecordsForBulkAction} = useCommonFunc()
    let selectAll = ref(false)

    let searchFilter  = ref('');
    let statusFilter  = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)
    let leads     = ref([])
    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getLeads()
    }
    let leadsCSVExport = reactive({
      modalOpen: false,
      selectedLeads: [],
    })

    let cancelToken = false
    const getLeads = async (reset) => {

      if(reset){
        this.pagination.page = 1;
      }
      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/advisor/leads', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            order: order.value,
            archived: 1,
            limit: 10,
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(!resp.data.success){
              alert(resp.data.message);
            }
            else{
              leads.value.splice(0);
              for(let x in resp.data.data){
                resp.data.data[x].selected = false;
                leads.value.push(resp.data.data[x]);
              }
              if(pagination.page === 1){
                pagination.totalRecords = resp.data.total_records;
              }
            }
          })
          .catch( err => {
            console.log('ReturnedLeads.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }
    const performBulkAction = (action)=>{
      const list = getSelectedRecordsForBulkAction(leads.value)
      if(!list){
        return;
      }
      if( action === 'export' ){
        leadsCSVExport.selectedLeads.splice(0)
        for(let x in list) leadsCSVExport.selectedLeads.push(list[x].AL_id)
        leadsCSVExport.modalOpen = true
      }
    }

    watch(searchFilter, () => getLeads(true))

    const searchLeads = (search) => {
      searchFilter.value = search
    }

    onIonViewWillEnter(()=>{
      getLeads()
    })

    // Update leads list, in case a lead get changed in other components
    watch(() => store.state.dataChangeTracker.advisor.archived_leads, (n) => {
      if(n){
        updateTracker('advisor', ['archived_leads'], false) // reset tracker
        getLeads()
      }
    })

    watch(selectAll, (n) => {
      for(let x in leads.value){
        leads.value[x].selected = n
      }
    })

    return {
      getLeads,
      leadStatuses,
      leads,
      leadsCSVExport,
      loadingData,
      order,
      pagination,
      paginationCallback,
      performBulkAction,
      searchBarActive,
      searchFilter,
      searchLeads,
      selectAll,
      statusFilter,
    }
  }
});

</script>
